import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import styled from "@emotion/styled"
import { theme, mediaQueries } from "../components/theme"
import { css } from "@emotion/core"
import { Container } from "../components/Container"

const Content = styled(Container)`
  padding-top: 60px;
  padding-bottom: 60px;
  color: ${theme.colors.blue};
  background-color: ${theme.colors.white};
  ${mediaQueries.medium(
    css`
      padding-top: 160px;
      padding-bottom: 160px;
      font-size: ${theme.headings.h1};
    `
  )};
  h1 {
    font-weight: 700;
    font-size: ${theme.headings.h2};
  }

  p {
    margin-bottom: 1em;
    font-size: 19px;
  }

  a {
    color: ${theme.colors.blue};
    font-weight: 700;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`

const Article = styled("article")`
  max-width: 750px;
  margin-left: auto;
  margin-right: auto;
`

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html, excerpt } = markdownRemark
  return (
    <Layout
      title={frontmatter.title}
      description={frontmatter.description}
    >
      <Content>
        <Article>
          <h1>{frontmatter.title}</h1>
          <p>{frontmatter.date}</p>
          <div
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </Article>
      </Content>
    </Layout>
  )
}

export const postQuery = graphql`
  query($slug: String) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      excerpt
      frontmatter {
        date(formatString: "D. M. YYYY")
        title
        templateKey
        description
      }
    }
  }
`;
